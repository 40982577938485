import React from 'react'

import Seo from '../components/seo';
import Layout from '../components/layout';

class BrandConfig extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Brand Config" />
          <div className="container-fluid container-fluid--page">
            <h1 className="text-center">Brand Config</h1>
            <h2>Colours</h2>
            <div className="flex-container">
              <p>$core-color-primary:</p>
              <div className="color-block color-block--primary" />
              <p>$core-color-secondary:</p>
              <div className="color-block color-block--secondary" />
              <p>$core-color-background:</p>
              <div className="color-block color-block--background" />
              <p>$core-color-neutral:</p>
              <div className="color-block color-block--neutral" />
            </div>
            <h2 className="heading-secondary">Typography</h2>
            <div className="flex-container">
              <h1>Heading Primary</h1>
              <h2>Heading Secondary</h2>
              <span className="subtitle">Subtitle</span>
              <p>Body Text Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
            <h2 className="heading-secondary">Buttons</h2>
            <div className="flex-container">
              <button className="button-primary">Primary Button</button>
              <button className="button-primary button-primary--dark">Primary Button Dark</button>
              <button className="button-secondary button-secondary">Secondary Button</button>
              <button className="button-secondary button-secondary--dark">Secondary Button Dark</button>
            </div>
          </div>
      </Layout>
    )
  }
}

export default BrandConfig;
